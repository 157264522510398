import * as fb from "../../firebase";
import moment from "moment";
import axios from "axios";
export default {
  state: {
    selectKioskAnalytics: "",
    selectedDuration: "Today",
    displayDate: "",
    todayBarChart: [
      { category: "8 AM", value: 0 },
      { category: "9 AM", value: 0 },
      { category: "10 AM", value: 0 },
      { category: "11 AM", value: 0 },
      { category: "12 PM", value: 0 },
      { category: "1 PM", value: 0 },
      { category: "2 PM", value: 0 },
      { category: "3 PM", value: 0 },
      { category: "4 PM", value: 0 },
      { category: "5 PM", value: 0 },
    ],
    pieChart: [
      { label: "Guide & File", value: 0 },
      { label: "File & Serve", value: 0 },
      { label: "Portal", value: 0 },
    ],
    paymentPieChart: [
      { label: "Initiated", value: 0 },
      { label: "Success", value: 0 },
      { label: "Cancelled", value: 0 },
    ],
    languageBarChart: [
      { category: "English", value: 0 },
      { category: "Spanish", value: 0 },
    ],
    summaryBarChart: [],
    pieChartTotal: 0,
    paymentPieChartTotal: 0,
    singleDatePicker: false,
    selectedSingleDate: null,
    weekDatePicker: false,
    selectedWeekDate: null,
    fiscalYearPicker: false,
    enteredFiscalYear: "",
    closeWithoutSelection: 0,
  },
  getters: {
    selectKioskAnalytics: (state) => state.selectKioskAnalytics,
    todayBarChart: (state) => state.todayBarChart,
    pieChart: (state) => state.pieChart,
    paymentPieChart: (state) => state.paymentPieChart,
    getPieChartTotal: (state) => state.pieChartTotal,
    getPaymentPieChartTotal: (state) => state.paymentPieChartTotal,
    languageBarChart: (state) => state.languageBarChart,
    summaryBarChart: (state) => state.summaryBarChart,
    selectedDuration: (state) => state.selectedDuration,
    singleDatePicker: (state) => state.singleDatePicker,
    selectedSingleDate: (state) => state.selectedSingleDate,
    weekDatePicker: (state) => state.weekDatePicker,
    selectedWeekDate: (state) => state.selectedWeekDate,
    displayDate: (state) => state.displayDate,
    fiscalYearPicker: (state) => state.fiscalYearPicker,
    enteredFiscalYear: (state) => state.enteredFiscalYear,
    closeWithoutSelection: (state) => state.closeWithoutSelection,
  },
  mutations: {
    setSelectKioskAnalytics(state, kioskid) {
      state.selectKioskAnalytics = kioskid;
    },
    setSelectedDuration(state, duration) {
      state.selectedDuration = duration;
    },
    setPieChartTotal(state, total) {
      state.pieChartTotal = total;
    },
    setPaymentPieChartTotal(state, total) {
      state.paymentPieChartTotal = total;
    },
    setSummaryBarChart(state, summary) {
      state.summaryBarChart.push(summary);
    },
    clearSummaryBarChart(state) {
      state.summaryBarChart = [];
    },
    setSingleDatePicker(state, status) {
      state.singleDatePicker = status;
    },
    setSelectedSingleDate(state, date) {
      state.selectedSingleDate = date;
    },
    setWeekDatePicker(state, status) {
      state.weekDatePicker = status;
    },
    setSelectedWeekDate(state, dates) {
      state.selectedWeekDate = dates;
    },
    setDisplayDate(state, dateString) {
      state.displayDate = dateString;
    },
    setFiscalYearPicker(state, status) {
      state.fiscalYearPicker = status;
    },
    setEnteredFiscalYear(state, year) {
      state.enteredFiscalYear = year;
    },
    setcloseWithoutSelection(state, value) {
      state.closeWithoutSelection = value;
    },
    resetPieChart(state) {
      state.pieChart = [
        { label: "Faqs", value: 0 },
        { label: "Maps", value: 0 },
        { label: "Hearings", value: 0 },
        { label: "Jury Duty", value: 0 },
      ];
    },
    resetPaymentPieChart(state) {
      state.paymentPieChart = [
        { label: "Initiated", value: 0 },
        { label: "Success", value: 0 },
        { label: "Cancelled", value: 0 },
      ];
    },
    resetLanguageBarChart(state) {
      state.languageBarChart = [
        { category: "English", value: 0 },
        { category: "Spanish", value: 0 },
      ];
    },
    resetTodayBarChart(state) {
      state.todayBarChart = [
        { category: "8 AM", value: 0 },
        { category: "9 AM", value: 0 },
        { category: "10 AM", value: 0 },
        { category: "11 AM", value: 0 },
        { category: "12 PM", value: 0 },
        { category: "1 PM", value: 0 },
        { category: "2 PM", value: 0 },
        { category: "3 PM", value: 0 },
        { category: "4 PM", value: 0 },
        { category: "5 PM", value: 0 },
      ];
    },
  },
  actions: {
    getKioskAnalytics({ commit, getters, dispatch }, duration) {
      return new Promise((resolve, reject) => {
        let analyticsObj = {};
        let usersessionList = [];
        const today = new Date();
        let startDate, endDate;
        let startDateOld, endDateOld;
        if (duration.type === "Today") {
          today.setHours(0, 0, 0, 0);
          startDate = today.toISOString();
          const today1 = new Date();
          const tomorrow = new Date(today1);
          tomorrow.setDate(today1.getDate() + 1);
          endDate = tomorrow.toISOString();
          commit("setDisplayDate", moment(startDate).format("L"));
        } else if (duration.type === "Yesterday") {
          today.setHours(0, 0, 0, 0);
          endDate = today.toISOString();
          const tempDate = new Date();
          const yesterday = new Date(tempDate);
          yesterday.setDate(tempDate.getDate() - 1);
          yesterday.setHours(0, 0, 0, 0);
          startDate = yesterday.toISOString();
          commit("setDisplayDate", moment(startDate).format("L"));
        } else if (duration.type === "Select Date") {
          startDate = getters.selectedSingleDate.startDate.toISOString();
          endDate = getters.selectedSingleDate.endDate.toISOString();
          commit("setDisplayDate", moment(startDate).format("L"));
        } else if (duration.type === "This Week") {
          const weekToday = new Date();
          const currentDayOfWeek = weekToday.getDay();
          const startW = new Date(weekToday);
          startW.setDate(weekToday.getDate() - currentDayOfWeek);
          startW.setHours(0, 0, 0, 0);
          startDate = startW.toISOString();
          const endW = new Date(weekToday);
          endW.setDate(weekToday.getDate() + (6 - currentDayOfWeek));
          endW.setHours(0, 0, 0, 0);
          endDate = endW.toISOString();
          commit(
            "setDisplayDate",
            moment(startDate).format("L") + " - " + moment(endDate).format("L")
          );
        } else if (duration.type === "Select Week") {
          startDate = getters.selectedWeekDate.startDate.toISOString();
          endDate = getters.selectedWeekDate.endDate.toISOString();
          commit(
            "setDisplayDate",
            moment(startDate).format("L") + " - " + moment(endDate).format("L")
          );
        } else if (duration.type === "2024") {
          const annualToday = new Date();
          const oneYearAgo = new Date(annualToday);
          oneYearAgo.setFullYear(annualToday.getFullYear() - 1);
          startDateOld = oneYearAgo.toISOString();
          endDateOld = annualToday.toISOString();
          const year = new Date().getFullYear();
          startDate = `${year}-01-01T00:00:00.000Z`;
          endDate = `${year}-12-31T23:59:59.999Z`;
          commit("setDisplayDate", "Annual " + annualToday.getFullYear());
        } else if (duration.type === "Fiscal Year") {
          startDate = getters.enteredFiscalYear.startDate.toISOString();
          endDate = getters.enteredFiscalYear.endDate.toISOString();
          commit(
            "setDisplayDate",
            moment(startDate).format("L") + " - " + moment(endDate).format("L")
          );
        } else {
          console.log("Custom Duration");
        }
        let config1 = {
          method: "get",
          maxBodyLength: Infinity,
          url: `https://cms.dekalb.arsconnect.com/user-sessions?applicationId=${getters.selectKioskAnalytics}&sessionStartTime_gte=${startDate}&sessionStartTime_lte=${endDate}&_limit=-1`,
          headers: {},
        };

        let config2 = {
          method: "get",
          maxBodyLength: Infinity,
          url: `https://cms.dekalb.arsconnect.com/qnas?kioskID=${getters.selectKioskAnalytics}&timeStamp_gte=${startDate}&timeStamp_lte=${endDate}&_limit=-1`,
          headers: {},
        };
        console.log("Config 1: ", config1);
        axios.request(config1).then((response) => {
          //          console.log("User Sessions: ", response.data);
          dispatch("transformSessions", response.data).then((response) => {
            console.log("Response => ", response);
            usersessionList = response;
            console.log("Usersession list: ", usersessionList);
            // axios
            //   .request(config2)
            //   .then((response1) => {
            analyticsObj = {
              userSessionAnalytics: usersessionList,
              totalUserSessions: usersessionList.length,
            };
            console.log("Analytics object: ", analyticsObj);
            commit("setKioskAnalytics", analyticsObj);
            commit("resetTodayBarChart");
            commit("resetPieChart");
            commit("resetPaymentPieChart");
            commit("resetLanguageBarChart");
            console.log("User Session List: ", usersessionList);
            if (Object.keys(usersessionList).length !== 0) {
              if (duration.type === "Today") {
                dispatch("createTodayBarChart", usersessionList);
              } else if (duration.type === "Yesterday") {
                dispatch("createTodayBarChart", usersessionList);
              } else if (duration.type === "Select Date") {
                dispatch("createTodayBarChart", usersessionList);
              }
              dispatch("createPieChart", usersessionList);
             // dispatch("createPaymentPieChart", response1.data);
              dispatch("createLanguageBarChart", usersessionList);
            } else {
              //
            }
            resolve("Kiosk analytical data fetched");
            // })
            // .catch((error) => {
            //   reject(error.message);
            // });
          });
        });
      });
    },
    createTodayBarChart({ getters, commit }, usersessionList) {
      usersessionList.forEach((session) => {
        let day = moment(session.sessionEndTime).format("H");
        switch (day) {
          case "8":
            var eight = getters.todayBarChart.find(
              (item) => item.category === "8 AM"
            );
            eight.value = eight.value + 1;
            break;
          case "9":
            var nine = getters.todayBarChart.find(
              (item) => item.category === "9 AM"
            );
            nine.value = nine.value + 1;
            break;
          case "10":
            var ten = getters.todayBarChart.find(
              (item) => item.category === "10 AM"
            );
            ten.value = ten.value + 1;
            break;
          case "11":
            var eleven = getters.todayBarChart.find(
              (item) => item.category === "11 AM"
            );
            eleven.value = eleven.value + 1;
            break;
          case "12":
            var twelve = getters.todayBarChart.find(
              (item) => item.category === "12 PM"
            );
            twelve.value = twelve.value + 1;
            break;
          case "13":
            var thirteen = getters.todayBarChart.find(
              (item) => item.category === "1 PM"
            );
            thirteen.value = thirteen.value + 1;
            break;
          case "14":
            var fourteen = getters.todayBarChart.find(
              (item) => item.category === "2 PM"
            );
            fourteen.value = fourteen.value + 1;
            break;
          case "15":
            var fifteen = getters.todayBarChart.find(
              (item) => item.category === "3 PM"
            );
            fifteen.value = fifteen.value + 1;
            break;
          case "16":
            var sixteen = getters.todayBarChart.find(
              (item) => item.category === "4 PM"
            );
            sixteen.value = sixteen.value + 1;
            break;
          case "17":
            var seventeen = getters.todayBarChart.find(
              (item) => item.category === "5 PM"
            );
            seventeen.value = seventeen.value + 1;
            break;
        }
      });
    },
    createPieChart({ getters, commit }, usersessionList) {
      commit("resetPieChart");
      usersessionList.forEach((session) => {
        console.log("Session at get Pie Chart", session);
        // const data = JSON.parse(session.userSession);
        const data = session.sessionData;
        var faq = getters.pieChart.find((item) => item.label === "Faqs");
        faq.value =
          faq.value + data.filter((item) => item.response === "faqs").length;

        var map = getters.pieChart.find((item) => item.label === "Maps");
        map.value =
          map.value + data.filter((item) => item.response === "maps").length;

        var hearing = getters.pieChart.find(
          (item) => item.label === "Hearings"
        );
        hearing.value =
          hearing.value +
          data.filter((item) => item.response === "todayshearing").length;

        var juryDuty = getters.pieChart.find(
          (item) => item.label === "Jury Duty"
        );
        juryDuty.value =
          juryDuty.value +
          data.filter((item) => item.response === "checkin").length;

        commit(
          "setPieChartTotal",
          faq.value + map.value + hearing.value + juryDuty.value
        );
      });
    },
    createPaymentPieChart({ getters, commit }, paymentList) {
      commit("resetPaymentPieChart");
      var initiated = getters.paymentPieChart.find(
        (item) => item.label === "Initiated"
      );
      var success = getters.paymentPieChart.find(
        (item) => item.label === "Success"
      );
      var cancel = getters.paymentPieChart.find(
        (item) => item.label === "Cancelled"
      );
      initiated.value = paymentList.filter(
        (item) => item.paymentStatus === "INITIATED"
      ).length;
      success.value = paymentList.filter(
        (item) => item.paymentStatus === "SUCCESS"
      ).length;
      cancel.value = paymentList.filter(
        (item) => item.paymentStatus === "CANCELLED"
      ).length;

      console.log("Payment Pie Chart in analytics: ", getters.paymentPieChart);
      commit(
        "setPaymentPieChartTotal",
        success.value + initiated.value + cancel.value
      );
    },
    createLanguageBarChart({ getters }, usersessionList) {
      usersessionList.forEach((session) => {
        const data = JSON.parse(session.userSession);
        var english = getters.languageBarChart.find(
          (item) => item.category === "English"
        );
        english.value = usersessionList.length;

        var spanish = getters.languageBarChart.find(
          (item) => item.category === "Spanish"
        );
        spanish.value =
          spanish.value +
          data.filter((item) => item.response === "Language changed to Spanish")
            .length;
      });
    },

    getKioskUtilizationSummary({ getters, commit, dispatch }) {
      commit("clearSummaryBarChart");
      const weekToday = new Date();
      const currentDayOfWeek = weekToday.getDay();
      const startW = new Date(weekToday);
      startW.setDate(weekToday.getDate() - currentDayOfWeek);
      let startDate = startW.toISOString();
      const endW = new Date(weekToday);
      endW.setDate(weekToday.getDate() + (6 - currentDayOfWeek));
      let endDate = endW.toISOString();
      let prodKioskList = [];
      //prodKioskList = getters.allRegisteredKiosks;
      getters.allRegisteredKiosks.forEach((kiosk) => {
        if (kiosk.data().env === "Prod") {
          prodKioskList.push(kiosk);
        }
      });
      prodKioskList.sort((a, b) =>
        a.data().arsBrowserId.localeCompare(b.data().arsBrowserId)
      );
      prodKioskList.forEach((kiosk) => {
        commit(
          "setDisplayDate",
          moment(startDate).format("L") + " - " + moment(endDate).format("L")
        );
        let config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `https://cms.nc.arsconnect.com/user-sessions?browserKioskId=${
            kiosk.data().arsBrowserId
          }&sessionStartTime_gte=${startDate}&sessionStartTime_lte=${endDate}&_limit=-1`,
          headers: {},
        };
        axios.request(config).then((response) => {
          dispatch("transformSessions", response.data).then((response) => {
            let data1 = {
              id: kiosk.data().arsBrowserId,
              category: kiosk.data().name,
              value: response.length,
            };
            commit("setSummaryBarChart", data1);
          });
        });
      });
    },
    transformSessions({ commit }, sessions) {
      //console.log("Sessions: ", sessions);
      const sessionArray = [...sessions.values()];
      const grouped = sessionArray.reduce((acc, session) => {
        const { sessionId } = session;
        if (!acc[sessionId]) {
          acc[sessionId] = [];
        }
        acc[sessionId].push(session);
        return acc;
      }, {});

      const transformedData = Object.keys(grouped).map((sessionId) => {
        const group = grouped[sessionId];

        return {
          sessionId: sessionId,
          browserKioskId: group[0].applicationId,
          startTime: group[0].sessionStartTime,
          endTime: group[group.length - 1].sessionEndTime,
          sessionData: group.map((item) => ({
            ...item.sessionPayload,
            actionType: item.actionType,
            applicationId: item.applicationId,
            sessionId: item.sessionId
          })),
        };
      });

      return transformedData;
    },
  },
};
